// modules
import axios from 'axios';
import firebase from 'firebase/app'; // eslint-disable-line import/no-extraneous-dependencies

import 'firebase/auth'; // eslint-disable-line import/no-extraneous-dependencies
import 'firebase/firestore'; // eslint-disable-line import/no-extraneous-dependencies

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCRd2J5tBM3f2r7bevB05h8XI7o9ez0ODo',
  authDomain: 'auth.iris.premise.com',
  databaseURL: 'https://web-app-2668.firebaseio.com',
  projectId: 'web-app-2668',
  storageBucket: 'web-app-2668.appspot.com',
  messagingSenderId: '605311054175',
  appId: '1:605311054175:web:e30794d3278d3b68f206ea',
  measurementId: 'G-CBNN51N144'
};

if (!firebase.apps.length) {
  console.debug('api.js: firebase initialize');
  firebase.initializeApp(FIREBASE_CONFIG);
} else {
  console.debug('api.js: firebase reloaded');
}

let authStateReported = false;
firebase.auth().onAuthStateChanged(() => {
  authStateReported = true;
});

const waitForAppInitialized = () => {
  if (authStateReported) {
    return Promise.resolve(firebase);
  }

  return new Promise((resolve, reject) => {
    const j = new Date().getTime();
    let i = setInterval(() => {
      if (authStateReported) {
        clearInterval(i);
        i = null;
        resolve(firebase);
      }
      if (new Date().getTime() - j > 5000) {
        clearInterval(i);
        i = null;
        reject();
      }
    }, 100);
  });
};

const getFirebaseCredential = async () => {
  if (!authStateReported) {
    await waitForAppInitialized();
  }
  const failure = (e) => {
    e && console.error(e);
  };

  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return failure();
  }
  return currentUser.getIdToken().catch(failure);
};

// GLOBALS
const API_HOST = process.env.REACT_APP_API_HOST;
const CancelToken = axios.CancelToken;

const genericPost = async (url, data, token, callback, onCancel) => {
  const tokenId = await getFirebaseCredential();
  return axios({
    url,
    data,
    cancelToken:
      token &&
      new CancelToken((c) => {
        token.cancel = c;
      }),
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenId}`
    }
  })
    .then((r) => {
      const { data, status, headers } = r;
      callback(status === 200 ? null : status, data, headers);
    })
    .catch((e) => {
      if (!axios.isCancel(e)) {
        if (e.response) {
          const { status, headers } = e.response;
          callback(status, null, headers);
        } else {
          callback(e);
        }
      } else {
        onCancel && onCancel();
      }
    });
};

const genericGet = async (url, token, callback) => {
  const tokenId = await getFirebaseCredential();
  return axios({
    url,
    method: 'GET',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenId}`
    },
    cancelToken:
      token &&
      new CancelToken((c) => {
        token.cancel = c;
      })
  })
    .then((r) => {
      const { data, status, headers } = r;
      callback && callback(status === 200 ? null : status, data, headers);
    })
    .catch((e) => {
      if (!axios.isCancel(e)) {
        callback && callback(e);
      }
    });
};

export { API_HOST, genericGet, genericPost, waitForAppInitialized };
