import React from 'react';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import queryString from 'query-string';
import PropTypes from 'prop-types';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebaseui from 'firebaseui';

// Add the Firebase services that you want to use
import 'firebase/auth'; // eslint-disable-line import/no-extraneous-dependencies
import 'firebase/firestore'; // eslint-disable-line import/no-extraneous-dependencies

import 'firebaseui/dist/firebaseui.css';

// ours
import { genericPost, API_HOST, waitForAppInitialized } from './api';

const IMAGES = [
  'https://storage.googleapis.com/prod-premise-android-observation-us/7f52050f-c28b-4d7b-906b-cb78982100c6.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/610f7b5d-ff6c-44af-b8b2-66a79fc58bf3.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/2853d442-e2a5-4fc6-9cb3-316eb2f9372b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/9dc3e228-607c-4082-8f2d-9f90e818a457.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7ce58edd-d0d4-4af0-ab9c-4e6a78c4ebe6.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/becec0ad-3096-4b16-a0ea-93c98153a612.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/6a879cf6-77dc-43a6-8540-44bf4e30afc1.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/38bcbb06-8df0-49d2-acc8-a3b57fba16ba.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/71cc74ee-abbf-48a5-825b-e3ba7d27f35d.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/97432ca4-4653-45c6-b574-3b02170ec678.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/f4f81d6f-ddbd-4ad0-8d60-39a694b2f33b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/383fdd90-1d26-46cb-a209-f117e6bcf70a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/a250aac2-e82e-4983-9622-1bb74d186616.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7a0100e4-1b47-4f29-85ed-e7043d522c0a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/fa98139d-9e08-4b57-8844-5633aef16a0e.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/5d97cb12-fa4f-43ef-a913-8d2c7cc14aaa.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/1f369358-3a37-455b-889e-32f812617364.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/774a8de8-4451-49b7-92ce-da27fef08a0b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/cf65acc8-16d4-450f-9463-623ad4f6107c.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/bb94ed69-b652-46c0-af7c-fc181ceedf59.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/5606bf52-4c35-4d27-88f5-b27b2bae9b9f.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/a3f71f28-624b-4ba3-8c0d-a68eace96e69.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/bcbfd0ce-5c6b-446d-a510-a7070a442bf6.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/6120dc50-6fcf-4da7-8a69-2ff6b898563e.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/cbfed795-fc9d-42c1-a764-dd8df5f4c1d5.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/cd333309-106e-440a-b5db-48906ca94852.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7c2deeb1-dcdb-410e-9d7f-b19aaa393e5b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/875ee7b3-84d5-4ddd-97c4-f387233ad975.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/0e07c82a-cf26-4c54-a99e-48341dfe0b50.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/ce21c044-ff00-4982-be69-c7e26c8f0f2a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/12eb9774-bd2d-454d-86cd-ab2ba117800b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7d777510-9b80-4cca-be71-75581afd4530.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/e30ff13f-3bce-4c2f-8e70-dead0fe7f28b.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/8fd21b9f-251f-4c01-a463-76173790201f.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/323e8ddc-3d4e-42a7-959d-61440245e47a.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/709ab526-4c79-4da1-addc-4364be5075d2.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/77b0ea13-2ac5-4670-a3a7-1fbc6cf9ca40.jpg',
  'https://storage.googleapis.com/prod-premise-android-observation-us/7055716f-3e30-435d-835c-dcf3dea71740.jpg'
];

const styles = () => ({
  container: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '30%',
    height: '100%'
  },
  content: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    transform: 'translate(0, -50%)'
  },
  logo: { paddingLeft: 16, paddingRight: 16, paddingBottom: 48 },
  infocontainer: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    margin: 32,
    padding: 32,
    color: '#424242'
  },
  info: { paddingBottom: 20 },
  resend: { marginRight: 16 },
  background: {
    top: 0,
    left: '30%',
    position: 'absolute',
    width: '70%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  }
});

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    const parsedQueryString = queryString.parse(window.location.search);
    if (parsedQueryString.origin) {
      console.debug(`constructor(): parsed origin from window.location: "${parsedQueryString.origin}"`);
    }
    this.state = {
      image: IMAGES[Math.floor(Math.random() * (IMAGES.length - 1))],
      origin: parsedQueryString.origin,
      token: parsedQueryString.token,
      emailVerified: true,
      firebaseUser: null,
      user: null,
      emailResent: false,
      ui: null,

      // snackbar
      actionFeedback: undefined,
      actionQueue: []
    };

    this._xhrSendEmail = {};

    // binds
    this.onAction = this.onAction.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExited = this.handleExited.bind(this);
  }
  redirect() {
    const { origin } = this.state;
    if (process.env.NODE_ENV === 'development') return console.debug(`redirect(): user OK: would have redirected to "${origin || '/'}"`);
    console.debug(`redirect() user OK: redirecting to "${origin || '/'}"`);
    window.location = origin || '/';
  }
  componentDidMount() {
    console.debug('componentDidMount(): waiting for firebase to initialize');
    waitForAppInitialized().then((firebase) => {
      const firebaseUser = firebase.auth().currentUser;
      console.debug(`componentDidMount(): firebase initialized, user status "${!!firebaseUser}", email verified "${firebaseUser && firebaseUser.emailVerified}"`);

      const { token } = this.state;
      if (!firebaseUser && token) {
        return firebase
          .auth()
          .signInWithCustomToken(token)
          .then((user) => {
            if (user) {
              this.redirect();
            } else {
              this.startUI(firebase);
            }
          });
      }

      // not logged in ~
      if (!firebaseUser) {
        console.debug('componentDidMount(): enabling firebase ui');
        return this.startUI(firebase);
      }

      const user = firebaseUser.toJSON();

      // logged in & email is verified ~
      if (user.emailVerified) {
        return this.redirect();
      }

      // logged in, but email not verified ~
      this.setState({ firebase, firebaseUser, user, emailVerified: false });
    });
  }
  sendEmailVerification(user, callback) {
    console.debug('sendEmailVerification()');
    genericPost(`${API_HOST}/admin/v0/users/email-verification/`, { uid: user.uid }, this._xhrSendEmail, (e, r) => {
      if (callback) callback(e);
      if (!e && r) {
        this.onAction('Sent.');
      } else if (e) {
        this.onAction('Verification email failed to send. Please try again after a few minutes.');
      }
    });
  }

  // for snackbar
  onAction(feedback) {
    this.setState((s) => ({
      actionQueue: [...s.actionQueue, { feedback, key: new Date().getTime() }]
    }));
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ actionFeedback: undefined });
  }

  handleExited() {
    this.setState({ actionFeedback: undefined });
  }

  componentDidUpdate() {
    // with previous feedback
    const { actionFeedback, actionQueue } = this.state;
    if (actionQueue.length && (!actionFeedback || (actionFeedback && actionFeedback.key !== actionQueue[0].key))) {
      this.setState({ actionFeedback: actionQueue[0], actionQueue: actionQueue.slice(1) });
    }
  }

  startUI(firebase) {
    if (this.state.ui) {
      return console.warn('startUI(): ui already started');
    }
    console.debug('startUI(): starting AuthUI');
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', {
      signInSuccessUrl: window.location.origin,
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          console.debug('startUI(): signInSuccessWithAuthResult()');
          // user was logged in ~
          if (authResult && authResult.user) {
            const firebaseUser = authResult.user;
            const user = firebaseUser.toJSON();

            if (!user.emailVerified) {
              console.debug('startUI(): signInSuccessWithAuthResult() user logged in w/o verified email');
              this.sendEmailVerification(user);
              this.setState({ emailVerified: false, firebaseUser, user });

              return false; // failure, do not redirect
            } else {
              console.debug('startUI(): signInSuccessWithAuthResult() user logged in w/ verified email');
              return true; // success, redirect
            }
          }

          console.debug('startUI(): signInSuccessWithAuthResult() undefined behavior', { authResult, redirectUrl });
          this.setState({ emailVerified: false });

          return false; // failure, do not redirect
        }
      },
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          scopes: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'],
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: 'select_account'
          }
        },
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true
        }
      ]
    });
    this.setState({ firebase, ui });
  }

  render() {
    const { classes } = this.props;
    const { image, emailVerified, user, actionFeedback } = this.state;
    const feedbackIsError = actionFeedback && actionFeedback.feedback.includes('fail');

    return (
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.logo}>
              <img alt="logo" src="/login/images/PremiseLogo_rev.svg" />
            </div>
            {emailVerified ? null : (
              <div className={classes.infocontainer}>
                <p className={classes.info}>
                  Check your email to verify your account. It may take a few moments to arrive and could land in your spam folder.
                  <br />
                  <br />
                  Click on the link in the email, and once your account is verified, please contact your Premise project lead for data access.
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.resend}
                  onClick={() => {
                    this.setState({ emailResent: true });
                    this.onAction('Sending the verification email...');
                    this.sendEmailVerification(user, (e) => {
                      if (e) this.setState({ emailResent: false });
                    });
                  }}
                >
                  Resend
                </Button>
              </div>
            )}
            <div id="firebaseui-auth-container" />
          </div>
        </div>
        <Snackbar
          autoHideDuration={6000}
          key={actionFeedback ? actionFeedback.key : undefined}
          open={!!actionFeedback}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionProps={{ onExited: this.handleExited }}
        >
          <MuiAlert
            elevation={6}
            variant={feedbackIsError ? 'filled' : 'standard'}
            severity={feedbackIsError ? 'error' : 'info'}
            action={
              <IconButton size="small" aria-label="close" color="default" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {actionFeedback ? actionFeedback.feedback : undefined}
          </MuiAlert>
        </Snackbar>
        <div className={classes.background} style={{ backgroundImage: `url("${image}")` }}></div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

App.defaultProps = {};

export default withStyles(styles)(App);
